import React from 'react'
// import { Link, graphql } from 'gatsby'
import classNames from 'classnames'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Layout from 'layout/layout'
import style from 'assets/jss/styles/pages/mainPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import SectionPriceExample from 'pages-sections/SectionPriceExample';
import { RightSection, LeftSection } from 'pages/reformas/reformas-banos-en-barcelona';

const useStyles = makeStyles(style)

const Electricistas = ({ data }) => {
  const classes = useStyles()
  return (
    <Layout
      pageTitle="Electricistas Barcelona"
      pageDescription="Especialistas en instalaciones industriales, comerciales y domésticas. Instalaciones eléctricas empotradas o de superficie. Ponemos en foco en el detalle, magníficos acabados. Centros de transformación, acometidas e instalaciones de interior. Electricistas Barcelona, especialistas en reformas."
      headerImageGatsby={data.electricidadJson.headerImage.childImageSharp.fluid}
      filter="dark"
      content={<span>
        <h1 className={classes.title}>{data.electricidadJson.title}</h1>
        <h2 className={classes.subtitle}>{data.electricidadJson.subtitle}</h2>
        </span>
      }
    >
      <div dangerouslySetInnerHTML={{__html: data.electricidadJson.content.childMarkdownRemark.html}} />

      {data.electricidadJson.gallery.map((obj, i) => {
        if (i % 2 == 0) {
          return (
            <LeftSection
              key={i}
              classes={classes} 
              image={obj.image.childImageSharp.fluid}
              alt={obj.alt}
              text={obj.text}
              title={obj.title}
            />
          )
        } else return (
          <RightSection 
            key={i}
            classes={classes} 
            image={obj.image.childImageSharp.fluid}
            alt={obj.alt}
            text={obj.text}
            title={obj.title}
          />
        )
      })}

      <SectionPriceExample 
        title="Precio de instalación eléctrica de pisos en Barcelona - Caso"
        subtitle="¿Qué precio medio tiene una instalación eléctrica de un piso en Barcelona?"
        priceText="El precio medio de una instalación eléctrica de un piso, de calidades medias y una superficie de 70m2 es de"
        keyword="electricistas"
        price="4000"
        minPrice="2200"
        maxPrice="8500"
      />
          
    </Layout>
  )
}

export default Electricistas

export const query = graphql`
  query ReformasElectricidadpageQuery {
    electricidadJson {
      title
      subtitle
      headerImage {
        childImageSharp {
          fluid(maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        title
        text
        alt
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;