import React from 'react'
// import { Link, graphql } from 'gatsby'
import classNames from 'classnames'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Layout from 'layout/layout'
import style from 'assets/jss/styles/pages/mainPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import SectionPriceExample from 'pages-sections/SectionPriceExample';

const useStyles = makeStyles(style)

export const RightSection = ({ classes, image, alt, text, title }) => (
  <GridContainer className={classes.container}>
    <GridItem xs={12} sm={5} md={5} className={classes.mlAuto + " " + classes.middle}>
      <Img
        fluid={image}
        alt={alt}
        title={alt}
      />
    </GridItem>
    <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
      <h3 className={classes.info + " " + classes.titleSection}>
        {title}
      </h3>
      <p className={classes.info}>
        {text}
      </p>
    </GridItem>
  </GridContainer>
)

export const LeftSection = ({ classes, image, alt, text, title }) => (
  <GridContainer className={classes.container}>    
    <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
      <h3 className={classes.info + " " + classes.titleSection}>
        {title}
      </h3>
      <p className={classes.info}>
        {text}
      </p>
    </GridItem>
    <GridItem xs={12} sm={5} md={5} className={classes.mrAuto + " " + classes.middle}>
      <Img
        fluid={image}
        alt={alt}
        title={alt}
      />
    </GridItem>
  </GridContainer>
)

const ReformasBaños = ({ data }) => {
  const classes = useStyles()
  return (
    <Layout
      pageTitle="Reformas baños en Barcelona."
      pageDescription="Especialistas en reformas de baños en Barcelona y alrededores. Pídenos presupuesto sin compromiso, te asesoramos de las distintas calidades y técnicas para que elijas lo más adecuado para ti."
      headerImageGatsby={data.reformasBanosJson.headerImage.childImageSharp.fluid}
      filter="dark"
      content={<span>
        <h1 className={classes.title}>{data.reformasBanosJson.title}</h1>
        <h2 className={classes.subtitle}>{data.reformasBanosJson.subtitle}</h2>
        </span>
      }
    >
      <div dangerouslySetInnerHTML={{__html: data.reformasBanosJson.content.childMarkdownRemark.html}} />

      {data.reformasBanosJson.gallery.map((obj, i) => {
        if (i % 2 == 0) {
          return (
            <LeftSection
              key={i}
              classes={classes} 
              image={obj.image.childImageSharp.fluid}
              alt={obj.alt}
              text={obj.text}
              title={obj.title}
            />
          )
        } else return (
          <RightSection 
            key={i}
            classes={classes} 
            image={obj.image.childImageSharp.fluid}
            alt={obj.alt}
            text={obj.text}
            title={obj.title}
          />
        )
      })}

      <SectionPriceExample 
        title="Precio de reformas baños en Barcelona - Caso"
        subtitle="¿Qué precio medio tiene un trabajo de reformas baños en Barcelona?"
        priceText="El precio medio de una reforma de baño típica, de calidades medias y una superficie de 4m2 es de"
        keyword="reformas baños"
        price="2000"
        minPrice="1200"
        maxPrice="4500"
      />
          
    </Layout>
  )
}

export default ReformasBaños

export const query = graphql`
  query ReformasBanospageQuery {
    reformasBanosJson {
      title
      subtitle
      headerImage {
        childImageSharp {
          fluid(maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        title
        text
        alt
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;